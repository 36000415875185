<div class="contact-form">
  <div class="form">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="form-group">
        <label i18n="@@Name">Name</label>
        <input type="text" i18n-placeholder="@@Your name" placeholder="Your name" formControlName="name" class="form-control" [ngClass]="{
            'is-invalid': formControl['name'].invalid && (formControl['name'].dirty || formControl['name'].touched)
          }" />
        @if (formControl['name'].invalid && (formControl['name'].dirty || formControl['name'].touched)) {
          <div class="invalid-feedback">
            @if (formControl['name'].errors?.['required']) {
              <div class="font-error" i18n="@@Name is required">Name is required</div>
            } @if (formControl['name'].errors?.['minlength']) {
              <div class="font-error" i18n="@@Name must have more than 2 characters">Name must have more than 2 characters</div>
            } @if (formControl['name'].errors?.['pattern']) {
              <div class="font-error" i18n="@@Name must contain only letters">Name must contain only letters</div>
            }
          </div>
        }
      </div>

      <div class="form-group">
        <label i18n="@@Email">Email</label>
        <input type="email" i18n-placeholder="@@Your email" placeholder="Your email" formControlName="email" class="form-control"
          [ngClass]="{ 'is-invalid': formControl['email'].invalid && (formControl['email'].dirty || formControl['email'].touched) }" />
        @if (formControl['email'].invalid && (formControl['email'].dirty || formControl['email'].touched)) {
          <div class="invalid-feedback">
          @if (formControl['email'].errors?.['required']) {
            <div class="font-error" i18n="@@Email is required">Email is required</div>
          } @if (formControl['email'].errors?.['email']) {
            <div class="font-error" i18n="@@Email is not">Email is not</div>
          }
        </div>
        }
      </div>
      <div class="form-group">
        <label i18n="@@Phone number *span*(optional)*span*">Phone number <span>(optional)</span></label>
        <input i18n-placeholder="@@Your phone number" placeholder="Your phone number" formControlName="phoneNumber" class="form-control" [ngClass]="{
            'is-invalid': formControl['phoneNumber'].invalid && (formControl['phoneNumber'].dirty || formControl['phoneNumber'].touched)
          }" />
        @if (formControl['phoneNumber'].invalid && (formControl['phoneNumber'].dirty || formControl['phoneNumber'].touched)) {
          <div class="invalid-feedback">
            @if (formControl['phoneNumber'].errors?.['pattern']) {
              <div class="font-error" i18n="@@The phone number must not contain letters and must be in an international format. Example: +481234567890">
                The phone number must not contain letters and must be in an
                international format. Example: +481234567890
              </div>
          }
        </div>
        }
      </div>
      <div class="form-group">
        <label i18n="@@How can we help you?">How can we help you?</label>
        <textarea type="text" i18n-placeholder="@@Describe your project" placeholder="Describe your project" formControlName="helpText" class="form-control"
          [ngClass]="{
            'is-invalid': formControl['helpText'].invalid && (formControl['helpText'].dirty || formControl['helpText'].touched)
          }"></textarea>
        @if (formControl['helpText'].invalid && (formControl['helpText'].dirty || formControl['helpText'].touched)) {
          <div class="invalid-feedback message">
          @if (formControl['helpText'].errors?.['required']) {
            <div class="font-error" i18n="@@Describe your project is required">Describe your project is required</div>
          }
        </div>
        }
      </div>
      <div class="form-group">
        <label i18n="@@Link to additional materials *span*(optional)*span*">Link to additional materials <span>(optional)</span></label>
        <input type="text" i18n-placeholder="@@Your links to additional materials" placeholder="Your links to additional materials" formControlName="additional" />
      </div>
      <div class="form-group form-check send-nda">
        <input type="checkbox" id="sendNDA" formControlName="isNeedNDA" class="form-check-input" />
        <span class="checkmark"></span>
        <label for="sendNDA" class="form-check-label" i18n="@@Send me NDA">Send me NDA</label>
      </div>
      <div class="form-group">
        <button type="submit" class="button-primary l" i18n="@@Submit" [disabled]="form.invalid">Submit</button>
      </div>
    </form>
  </div>
  <div class="privacy-text">
    <p class="m normal" i18n="@@By submitting this form, you consent to the processing of your data in accordance with our *span*Privacy Policy.*span*">
      By submitting this form, you consent to the processing of your data in accordance with our <span routerLink="/privacy-policy">Privacy Policy.</span>
    </p>
  </div>
</div>
