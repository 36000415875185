import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ContactFormData, JoinFormData } from '@interfaces/form-data.interface';
import { FormSubmitResponse } from '@interfaces/response.interface';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root',
})
export class FormSubmitService {
  constructor(private http: HttpClient) {}

  public submitContactForm(data: ContactFormData): Observable<any> {
    const body = `
      mutation SendContactUsEmail($data: SendContactUsEmailInput!) {
        sendContactUsEmail(data: $data)
      }
    `;

    return this.http
      .post<FormSubmitResponse>(environment.pixelUrl, {
        query: JSON.parse(JSON.stringify(body)),
        variables: { data: data },
        operationName: 'SendContactUsEmail',
      })
      .pipe(map((res) => res.data));
  }

  public submitJoinForm(data: JoinFormData): Observable<any> {
    const body = `
      mutation SendJoinUsEmail($data: SendJoinUsEmailInput!) {
        sendJoinUsEmail(data: $data)
      }
    `;

    const formData = new FormData();

    formData.append(
      'operations',
      JSON.stringify({
        query: body,
        variables: {
          data: {
            email: data.email,
            message: data.message,
            name: data.name,
            phoneNumber: data.phoneNumber || null,
          },
        },
        operationName: 'SendJoinUsEmail',
      })
    );

    const mapFile = { 0: ['variables.data.file'] };
    formData.append('map', JSON.stringify(mapFile));

    if (data.file) formData.append('0', data.file);

    return this.http
      .post<FormSubmitResponse>(environment.pixelUrl, formData)
      .pipe(map((res) => res.data));
  }
}
